import React from "react"
import styled from "styled-components"
import Layout from "../../template/Layout"
import Img from "gatsby-image"
import Head from "../../head";
import { navigate } from "gatsby"
import { RESPONSIVE_STYLES, WRAPPER_STYLES } from "../../AppConst"
import { ImgWrapper } from "../common/ImageWrapper"
import Button from "../common/RecruitmentButton"

/**
 * Styles
 */
const Container = styled.div`
    ${WRAPPER_STYLES}
    width: 500px;
    text-align: center;
    @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
        width: 90%;
    }
    & .not-found-title {
        font-size: 4.0rem;
        font-family: 'futura-pt';
        font-style: italic;
        letter-spacing: 3.15px;
        font-weight: 500px;
        margin-top: 120px;
        margin-bottom: 66px;
        @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
            font-size: 3.0rem;
            font-family: 'futura-pt';
            font-style: italic;
            letter-spacing: 2.37px;
            font-weight: 500px;
        }
    }
    & .pc__img {
        width: 460px;
        height: 270px;
        margin-left: auto;
        margin-right: auto;
    }
    & .sp__img {
        width: 100%;
    }
    & .not-found-buttom {
        margin-top: 80px;
        margin-bottom: 100px;
        @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
            width: 90%;
            max-width: 335px;
            height: 40px;
            margin-left: auto;
            margin-right: auto;
        }
    }
`

/**
 * Props
 */
type Props = {
    img: any;
}

/**
 * Component
 */
export const NotFound: React.FC<Props> = (props) => {
    return (
        <Layout sidebar={false}>
            <Head
                title="404 Not Found | ネクストライブ株式会社"
                description=""
            />
            <Container>
                <div className="not-found-title">404 NOT FOUND</div>
                <ImgWrapper>
                    <Img className='pc__img' fluid={props.img} alt='404' />
                    <Img className='sp__img' fluid={props.img} alt='404' />
                </ImgWrapper>
                <div className='not-found-buttom'>
                    <Button text='トップページへ' url={'/'}/>
                </div>
            </Container>
        </Layout>
    )
}