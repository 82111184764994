import React from "react"
import { graphql } from "gatsby"
import { NotFound } from "../components/404"

export default function NotFoundPage({ data }) {
    const {
        notFound
    } = data
    return (
        <NotFound img={notFound.childImageSharp.fluid} />
    )
}

export const query = graphql`
    query NotFoundQuery {
        notFound: file(relativePath: { eq: "images/img_404@2x.png"}) {
            childImageSharp {
                fluid(maxWidth: 500, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`