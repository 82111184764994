import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../AppConst"

export const ImgWrapper = styled.div<{ maxWidth?: number }>`
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : `100%`)};
  & .sp__img {
    display: none;
    @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
      display: block;
    }
  }
  & .pc__img {
    display: block;
    @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
      display: none;
    }
  }
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH};
    width: 100%;
    height: auto;
    & > img {
      width: auto;
      height: auto;
    }
  }
`
